import axios from "@/plugins/axios";

export default {
  async listar(dados) {
    const parametros = new URLSearchParams(dados).toString();
    return await axios.get(`area_configuracao?${parametros}`);
  },

  async buscar(id) {
    return await axios.get(`area_configuracao/${id}`);
  },

  async inserir(dados) {
    return await axios.post("area_configuracao", dados);
  },

  async atualizar(id, dados) {
    return await axios.put(`area_configuracao/${id}`, dados);
  },
  async tags() {
    return await axios.get("area_configuracao/layout-tags");
  },
};
