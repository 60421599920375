<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/area_configuracaoAdmin" />
        </v-col>
      </v-row>
    </v-card-actions>
    <area-configuracao-campos
      :areas="areas"
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import areaConfiguracao from "@/api/areaconfiguracao";
import areas from "@/api/empresas";

export default {
  components: {
    AreaConfiguracaoCampos: () => import("./AreaConfiguracaoCampos"),
  },

  data() {
    return {
      carregando: false,
      validacaoFormulario: {},
      areas: [],
    };
  },

  mounted() {
    this.buscarAreas();
  },

  methods: {
    async salvar(configuracao) {
      this.carregando = true;
      this.validacaoFormulario = {};

      try {
        await areaConfiguracao.inserir(configuracao);

        this.$router.push("/area_configuracaoAdmin", () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_adicionar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.carregando = false;
      }
    },

    async buscarAreas() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);

        const resposta = await areas.listarAreasEmpresa(
          this.$route.params.empresa
        );

        this.areas = resposta.data.data;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        console.log(e);
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>
